<template>
  <v-container fluid>
    <v-row no-gutters>
      <!-- Main Card -->
      <v-col cols="12" v-if="isLoaded" class="mt-6">
        <v-card class="mx-2">
          <v-row no-gutters>
            <v-col cols="12" class="mt-2">
              <v-row justify="space-between" no-gutters>
                <div class="mx-2">
                  <h1>{{ item.name }}</h1>
                </div>
                <div class="mx-2">
                  <v-chip label class="mx-1">{{ item.type }}</v-chip>
                  <v-btn icon @click="setShowMainEditDialog(true)">
                    <v-icon>fas fa-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="setShowDeleteDialog(true)">
                    <v-icon>far fa-trash</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-col>

            <!-- General info -->
            <v-col cols="12">
              <v-row no-gutters class="ma-2">
                <!-- FM Referent -->
                <v-col cols="12">
                  <span>{{ $t("accounts.labels.fm_referent") }} :</span>
                  <template v-for="fmAccount of item.fm_referents">
                    <FMReferent :key="fmAccount.id" :item="fmAccount" />
                  </template>
                </v-col>

                <!-- Parent -->
                <v-col v-if="item.parent" cols="12">
                  <span>{{ $t("accounts.labels.parent") }}</span>
                  <router-link
                    :to="{ name: 'Account', param: { id: item.parent.id } }"
                  >
                    {{ item.parent.name }}
                  </router-link>
                </v-col>

                <!-- Main Address -->
                <v-col cols="12">
                  <span class="heading">
                    {{ $t("accounts.labels.main_address") }} :
                  </span>
                  <AddressInline :address="item.address" />
                </v-col>

                <!-- TODO : Registration -->
                <v-col cols="12"></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Tabs card -->
      <v-col cols="12" class="mt-6">
        <v-card class="mx-2">
          <v-tabs class="mx-4" v-model="tab" grow @change="switchTab">
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-1"
              :exact="true"
            >
              {{ $t("accounts.tabs.contacts.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-2"
              :exact="true"
            >
              {{ $t("accounts.tabs.products.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-3"
              :exact="true"
            >
              {{ $t("accounts.tabs.users.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-4"
              :exact="true"
            >
              {{ $t("accounts.tabs.api-tokens.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              href="#tab-5"
              :exact="true"
            >
              {{ $t("accounts.tabs.settings.title") }}
            </v-tab>
            <v-tab
              class="text-transform-none font-size-14"
              disabled
              href="#tab-6"
              :exact="true"
            >
              {{ $t("accounts.tabs.subscription.title") }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="ma-1">
            <!-- Contacts -->
            <v-tab-item value="tab-1">
              <AccountContactsTab v-if="item" :account="item" />
              <Skeleton v-else type="table" :occurrence="1" />
            </v-tab-item>

            <!-- Products -->
            <v-tab-item value="tab-2">
              <CommonStudiesTab v-if="item" :account="item" />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>

            <!-- Users -->
            <v-tab-item value="tab-3">
              <AccountUsersTab v-if="item" :account="item" />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>

            <!-- Api Tokens -->
            <v-tab-item value="tab-4">
              <AccountApiTokensTab v-if="item" :account="item" />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>

            <!-- Settings -->
            <v-tab-item value="tab-5">
              <AccountSettingsTab
                v-if="item"
                :account="item"
                @refresh="getData"
              />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>

            <!-- Subscription -->
            <v-tab-item disabled value="tab-6">
              <AccountSubscriptionTab v-if="item" :account="item" />
              <Skeleton type="table" :occurrence="1" v-else />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <!-- Account main dialog -->
    <AccountMainDialog
      v-if="showDialog.main"
      :value="showDialog.main"
      :item="item"
      @close="(needRefresh) => setShowMainEditDialog(false, needRefresh)"
    />

    <!-- Account delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('accounts.deleteDialog.title')"
      @submit="deleteAccount"
      @close="(needRefresh) => setShowDeleteDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import formatter from "@/mixins/formatter";
import tabsUtils from "@/mixins/tabs-utils";

export default {
  name: "AccountView",

  components: {
    FMReferent: () => import("@/components/Common/Cards/FMReferent"),

    AddressInline: () => import("@/components/Common/Cards/AddressInline"),
    Skeleton: () => import("@/components/Common/Cards/Skeleton"),
    AccountMainDialog: () => import("@/components/Accounts/AccountMainDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),

    // Tabs
    AccountUsersTab: () =>
      import("@/components/Accounts/Users/AccountUsersTab"),
    AccountApiTokensTab: () =>
      import("@/components/Accounts/ApiTokens/AccountApiTokensTab"),
    AccountContactsTab: () =>
      import("@/components/Accounts/Contacts/AccountContactsTab"),
    AccountSettingsTab: () =>
      import("@/components/Accounts/Settings/AccountSettingsTab"),
    CommonStudiesTab: () =>
      import("@/components/Common/Studies/CommonStudiesTab"),
    AccountSubscriptionTab: () =>
      import("@/components/Accounts/Subscription/AccountSubscriptionTab"),
  },

  mixins: [formatter, tabsUtils],

  data() {
    return {
      showDialog: {
        main: false,
        delete: false,
      },

      isLoaded: false,
      isLoading: {
        btnSave: false,
      },
      item: null,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoaded = false;
      this.isLoading.btnSave = false;

      this.$http
        .get(`/accounts/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.item = res.data;
          this.$store.commit("pageHead/setTitle", `${this.item.name}`);
        })
        .catch((err) => {
          this.$store.commit("toast/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },

    deleteAccount() {
      this.$http
        .delete(`/accounts/${this.item.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("accounts.deleteDialog.success")
          );
          this.setShowDeleteDialog(false);
          this.$router.push({ name: "Accounts" });
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
    },

    setShowMainEditDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.main = val;
    },

    setShowDeleteDialog(val, reloadData = false) {
      if (reloadData) this.getData();

      this.showDialog.delete = val;
    },
  },
};
</script>

<style scoped></style>
