<template>
  <v-container fluid>
    <v-row justify="start" class="ma-2" no-gutters>
      <v-col cols="12">
        <h1>{{ $t("accounts.title") }}</h1>
      </v-col>

      <!-- Buttons -->
      <v-col cols="12" class="mt-6 text-right">
        <v-btn color="primary" @click="setShowAddDialog(true)">
          {{ $t("accounts.buttons.add") }}
        </v-btn>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>fas fa-ellipsis-v</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              :disabled="isLoading.exportUsers"
              @click="downloadExportUsers"
            >
              <v-list-item-icon>
                <v-icon small>fas fa-file-download</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t("accounts.export.users.btn") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <v-col cols="12" class="mt-6">
        <div>
          <v-text-field
            v-model="search"
            append-icon="fas fa-search"
            :label="$t('common.inputs.search')"
            clearable
            v-on:click:clear="clearInput"
            @keyup.enter.native="searchTrigger"
            outlined
          ></v-text-field>
        </div>
      </v-col>

      <!-- Table -->
      <v-col cols="12" class="mt-2">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          style="min-height: 600px"
          multi-sort
          :footer-props="{ 'items-per-page-options': itemsPerPage }"
          class="elevation-1"
        >
          <!-- Picture -->
          <template v-slot:item.picture="{ item }">
            <DisplayPicture
              :custom-width="50"
              :img="item.picture"
              :name="item.name"
            />
          </template>

          <!-- Name -->
          <template v-slot:item.name="{ item }">
            <router-link :to="{ name: 'Account', params: { id: item.id } }">
              {{ item.name }}
            </router-link>
          </template>

          <!-- Type -->
          <template v-slot:item.type="{ item }">
            {{ item.type }}
          </template>

          <!-- FM Referent -->
          <template v-slot:item.fm_referent="{ item }">
            <template v-for="fmAccount of item.fm_referents">
              <FMReferent :key="fmAccount.id" :item="fmAccount" />
            </template>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main brand dialog -->
    <AccountMainDialog
      v-if="showDialog.add"
      :value="showDialog.add"
      :item="null"
      @close="(needRefresh) => setShowAddDialog(false, needRefresh)"
    />
  </v-container>
</template>

<script>
import vuetifyDatatable from "@/mixins/vuetifyDataTable";
import formatter from "@/mixins/formatter";

export default {
  name: "AccountsView",

  components: {
    DisplayPicture: () => import("@/components/Common/Picture/DisplayPicture"),
    AccountMainDialog: () => import("@/components/Accounts/AccountMainDialog"),
    FMReferent: () => import("@/components/Common/Cards/FMReferent"),
  },

  mixins: [vuetifyDatatable, formatter],

  data() {
    return {
      headers: [
        {
          text: "",
          value: "picture",
          sortable: false,
        },
        {
          text: this.$t("accounts.datatable.headers.name"),
          value: "name",
          sortable: true,
          width: "30%",
        },
        {
          text: this.$t("accounts.datatable.headers.type"),
          value: "type",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("accounts.datatable.headers.fm_referent"),
          value: "fm_referent",
          sortable: false,
          width: "20%",
        },
      ],
      URL_API: {
        GET_ALL: "/accounts",
      },

      showDialog: {
        add: false,
      },
      isLoading: {
        exportUsers: false,
      },
      savePaginationURL: true,
    };
  },

  created() {
    this.$store.commit("pageHead/setTitle", this.$t("accounts.pageTitle"));
  },

  methods: {
    setShowAddDialog(val, reloadData = false) {
      if (reloadData) this.searchTrigger();
      this.showDialog.add = val;
    },

    goToOne(obj) {
      this.$router.push({
        name: "Account",
        params: { id: obj.id },
      });
    },

    downloadExportUsers() {
      this.isLoading.exportUsers = true;
      this.$store.commit(
        "toast/showSuccess",
        this.$t("accounts.export.users.waiting")
      );
      this.$http
        .get(`users/export-list`, {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + this.$session.get("jwt"),
          },
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export-users.csv");
          //document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch((err) => {
          this.$store.commit("toast/showError", this.$t("common.errors.500"));
        })
        .finally(() => {
          this.isLoading.exportUsers = false;
        });
    },
  },
};
</script>

<style scoped></style>
